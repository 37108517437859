import React from 'react';
import { Link } from 'gatsby';

import config from '../../../config/config';
import Logo from '../../data/logos/FishermanRedTextLogo.svg';
import '../../css/pages/privacy-policy.css';

export default class PrivacyPolicy extends React.Component {
  render() {
    return (
      <div>
        <div className="privacy-logo-container">
          <Link to="/" href="/">
            <img id="privacy-logo" src={Logo} alt="Fisherman Logo" />
          </Link>
        </div>
        <div id="privacy-title">Terms Of Service</div>
        <div id="privacy-content">
          Last Revised: April 9, 2021
          <br />
          <br />
          <b>1. &nbsp;Definitions.</b>
          <br />
          <br />
          1.1. The “User,” “Customer,” or “You” shall refer to direct users of Fisherman’s services
          and the business, business owner, or individual that Fisherman is directly servicing.
          <br />
          <br />
          1.2. The “End User” or “Website Visitor” shall refer to users of websites that Fisherman
          creates, not directly interacting with Fisherman, but with the Fisherman products.
          <br />
          <br />
          <b>2. &nbsp;Acceptance of the Terms of Service.</b>
          Welcome to the website of Fisherman Technologies, Inc. (the “Company”). The following
          terms and conditions, together with any documents they expressly incorporate by reference
          (collectively, the “Terms of Service”), govern your access to and use of{' '}
          <a href={config.baseConfig.siteUrl}>www.gofisherman.com</a> (the “Website”), including any
          content, functionality and services offered on or through the Website.
          <br />
          <br />
          Please read the Terms of Service carefully before you start to use the Website. By using
          the Website, you accept and agree to be bound and abide by these Terms of Service as well
          as the Company’s Privacy Policy, found at https://gofisherman.com/privacy_policy/ and
          incorporated herein by reference. If you do not want to agree to these Terms of Service
          and the Privacy Policy, you must not access or use the Website.
          <br />
          <br />
          <b>3. &nbsp;Changes to the Terms of Service.</b>
          <br />
          <br />
          The Company may revise and update these Terms of Service from time to time in its sole
          discretion. All changes are effective immediately when they are posted, and apply to all
          access to and use of the Website thereafter. Any revision or update to these Terms of
          Service will be accompanied by a change in the “Effective Date” at the top of these Terms
          of Service.
          <br />
          <br />
          Your continued use of the Website following the posting of revised or updated Terms of
          Service means that you accept and agree to the changes. You are expected to check this
          page frequently so you are aware of any changes, as they are binding on you.
          <br />
          <br />
          <b>4. &nbsp;Accessing the Website and Account Security/Use.</b>
          <br />
          <br />
          4.1. The Company reserves the right to withdraw or amend the Website, and any service or
          material provided on the Website, in the Company’s sole discretion and without notice. The
          Company will not be liable if for any reason all or any part of the Website is unavailable
          at any time or for any period. From time to time, the Company may restrict access to some
          parts of the Website, or the entire Website, to Users.
          <br />
          4.2. You are responsible for:
          <br />
          a. Making all arrangements necessary for you to have access to the Website; and
          <br />
          b. Ensuring that all persons who access the Website through your Internet connection are
          aware of these Terms of Service and comply with them.
          <br />
          4.3. You may be asked to provide certain registration details or other information by
          which you may be personally identified (“Personal Information”). It is a condition of your
          use of the Website that all the Personal Information you provide on the Website is
          correct, current, and complete. You agree that all Personal Information you provide to
          register with this Website or otherwise is governed by the Privacy Policy and you consent
          to all actions that the Company takes with respect to your Personal Information consistent
          with the Privacy Policy.
          <br />
          4.4. When you sign up to use the Company’s services through its website, your information
          is stored internally in its electronic records on its servers.
          <br />
          <br />
          <b>5. &nbsp;Services Provided to Customers.</b>
          <br />
          <br />
          5.1. When a Customer registers with the Company, the Company provides or intends to
          provide services such as: improving website load time, mobile-optimized design, Search
          Engine Optimization (SEO), website development, website design, editing, web security, web
          hosting, content creation and management, lead generation and management, data analytics,
          social media management, version updates, integrations with other technologies, and
          additional website features (blogs, catering form, online ordering, e-commerce, specials,
          events calendar, reservations).
          <br />
          5.2. Company reserves the right to solicit its Users for upsells for additional products
          and for feedback on its services.
          <br />
          5.3. Company reserves the right, in perpetuity and free of charge, to use any output of
          Fisherman technology, including, but not limited to, websites built using the Company’s
          platform, for any of the Company’s marketing and promotional activities.
          <br />
          <br />
          <b>6. &nbsp;Pricing.</b>
          <br />
          <br />
          6.1. Any price a User pays will be listed on the invoice it receives, which will reflect
          the services the Company will provide the User.
          <br />
          6.2. The User has a money back guarantee if the Company is notified within fourteen (14)
          days of initial purchase of any of the Company’s services.
          <br />
          <br />
          <b>7. &nbsp;Customer Service.</b>
          <br />
          <br />
          If a User needs help regarding the services provided by the Company, User may contact the
          Company at <a href="mailto:info@gofisherman.com">info@gofisherman.com</a> and Company will
          respond within a reasonable amount of time.
          <br />
          <br />
          <b>8. &nbsp;Intellectual Property Rights.</b>
          <br />
          <br />
          8.1. The Website and its entire contents, features, and functionality (including but not
          limited to all data, software, text, displays, images, video, and audio, and the design,
          selection, and arrangement thereof) are owned by the Company and are protected by United
          States copyright, trademark, patent, trade secret, and other intellectual property laws.
          <br />
          8.2. These Terms of Service permit you to use the Website for your personal,
          non-commercial use only. You must not reproduce, distribute, modify, create derivative
          works of, publicly display, publicly perform, republish, download, store, or transmit any
          of the material on our Website, except as follows:
          <br />
          a. Your computer may temporarily store copies of such materials in RAM incidental to your
          accessing and viewing those materials;
          <br />
          b. You may store files that are automatically cached by your Web browser for display
          enhancement purposes; or
          <br />
          c. If the Company provides social media features with certain content, you may take such
          actions as are enabled by such features.
          <br />
          8.3. You may not:
          <br />
          a. Modify copies of any materials from this Website;
          <br />
          b. Use any illustrations, photographs, video or audio sequences, or any graphics
          separately from the accompanying text; or
          <br />
          c. Delete or alter any copyright, trademark, or other proprietary rights notices from
          copies of materials from this site.
          <br />
          8.4. You may not access or use for any commercial purposes any part of the Website or any
          services or materials available through the Website.
          <br />
          8.5. If you wish to make any use of material on the Website other than that set out in
          this section, please address your request to:{' '}
          <a href="mailto:info@gofisherman.com">info@gofisherman.com</a>
          <br />
          8.6. If you print, copy, modify, download, or otherwise use or provide any other person
          with access to any part of the Website in breach of the Terms of Service, your right to
          use the Website will cease immediately and you must, at the Company’s option, return or
          destroy any copies of the materials you have made. No right, title, or interest in or to
          the Website or any content on the Website is transferred to you, and all rights not
          expressly granted are reserved by the Company. Any use of the Website not expressly
          permitted by these Terms of Service is a breach of these Terms of Service and may violate
          copyright, trademark, and other laws.
          <br />
          8.7. If you provide any feedback to the Company, which may or may not be subject to
          Intellectual Property rights, you agree that the Company exclusively owns such feedback.
          <br />
          <br />
          <b>9. &nbsp;Domains and Website URLs.</b>
          <br />
          <br />
          9.1. If the Company purchases a domain for the Customer, the Company owns the domain and
          the Customer must pay for the right to use the domain.
          <br />
          9.2. If the Customer owns a domain that it wants to use for Company services, fees may be
          charged by the Company to transfer, use, and/or connect the domain to the Company’s
          websites and servers. Customer agrees to pay such fees.
          <br />
          <br />
          <b>10. &nbsp;Trademarks.</b>
          <br />
          <br />
          The company name, Fisherman, the Company logo, and all related names, logos, product and
          service names, designs, and slogans are trademarks of the Company. You may not use such
          marks without the prior written permission of the Company. All other names, logos, product
          and service names, designs, and slogans displayed on the Website are the trademarks of
          their respective owners.
          <br />
          <br />
          <b>11. &nbsp;User’s Intellectual Property.</b>
          <br />
          <br />
          As between the Company and the User, the User shall own all intellectual property
          pertaining to content created by you, including, but not limited to, any logos, names, or
          literary works. The Company does not claim ownership rights in your content. For the sole
          purpose of granting you the service, you know and agree that the Company will need to
          access, upload and/or copy your content to its platform, including its servers, to make
          display adjustments, to duplicate for backup and perform any other technical actions
          and/or uses required to perform our services, as we deem fit. The User hereby grants a
          non-exclusive, royalty free license in such User’s intellectual property to the Company
          for such purposes.
          <br />
          <br />
          <b>12. &nbsp;Use of Websites by End Users.</b>
          <br />
          <br />
          12.1. The Company’s technology provides a platform for its Users to interact with and
          collect information about their End Users. Any End User details collected through the
          User’s website (e.g. a ‘Request a Quote’ form on the website) is owned directly by the
          User, and such information will be emailed directly to the User. The Company may also
          store these End User details in its own database in order to provide added value to its
          Users and End Users.
          <br />
          12.2. The Company may generate and add a Privacy Policy to each of its User’s websites.
          Note that the policy that is created is generic for the website and its use of data and
          it’s the responsibility of the User that elects to have the policy as part of these Terms
          to ensure it complies with the specific requirements of the state they’re operating in and
          types of data it requests to collect on its website. If the User would like to make
          changes to its Privacy Policy, they should contact the Fisherman support team at
          support@gofisherman.com
          <br />
          12.3. The Company tracks, collects, and may use information about how End Users operate on
          a User’s website (e.g. where End Users click, how they navigate on pages, how long they
          spend on a website), which the Company primarily uses to inform improved website
          interactions. The Company reserves the right to use this information for commercial
          purposes.
          <br />
          <br />
          <b>13. &nbsp;Third Party Services.</b>
          <br />
          <br />
          The Company’s services enable its Users to engage and procure certain third party
          services, products and tools for enhancing overall user experience, including, without
          limitation, third party licensed content, media distribution services, e-Commerce service
          providers, sellers of tangible products, etc. (collectively, “Third Party Services”).
          <br />
          You acknowledge and agree that regardless of the manner in which such Third Party Services
          may be offered to you (bundled or integrated within certain services or offered separately
          by the Company), the Company merely acts as an intermediary platform between you and such
          Third Party Services or shall be in any way responsible or liable with respect thereto.
          You acknowledge that such services may require the payment of additional amounts to the
          Company and/or to the providers of such Third-Party Services.
          <br />
          Any and all use of such Third Party Services shall be made solely at your own risk and
          responsibility, and may be subject to the legal and financial terms which govern such
          Third Party Services, which you are encouraged to review before engaging with them.
          <br />
          While the Company hopes to avoid such instances, the Company may, at any time and at its
          sole discretion, suspend, disable access to or remove from your website any Third Party
          Services without any liability to you or to any End Users
          <br />
          <br />
          <b>14. &nbsp;Prohibited Uses.</b>
          <br />
          <br />
          14.1. You may use the Website only for lawful purposes and in accordance with these Terms
          of Service. You agree not to use the Website:
          <br />
          a. In any way that violates any applicable federal, state, local, or international law or
          regulation (including but not limited to any laws regarding the export of data or software
          to and from the United States or other countries);
          <br />
          b. For the purpose of exploiting, harming, or attempting to exploit or harm minors in any
          way by exposing them to inappropriate content, asking for personally identifiable
          information, or otherwise; or
          <br />
          c. To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of
          the Website, or which, as determined by the Company, may harm the Company or the Users of
          the Website or expose them to liability.
          <br />
          14.2. Additionally, you agree not to:
          <br />
          a. Use the Website in any manner that could disable, overburden, damage, or impair the
          site or interfere with any other party's use of the Website, including their ability to
          engage in real time activities through the Website;
          <br />
          b. Use any robot, spider, or other automatic device, process, or means to access the
          Website for any purpose, including monitoring or copying any of the material on the
          Website;
          <br />
          c. Use any manual process to monitor or copy any of the material on the Website or for any
          other unauthorized purpose without our prior written consent;
          <br />
          d. Use any device, software, or routine that interferes with the proper working of the
          Website;
          <br />
          e. Introduce any viruses, worms, logic bombs, or other material that is malicious or
          technologically harmful;
          <br />
          f. Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of
          the Website, the server on which the Website is stored, or any server, computer, or
          database connected to the Website;
          <br />
          g. Attack the Website via a denial-of-service attack or a distributed denial-of-service
          attack; or
          <br />
          h. Otherwise attempt to interfere with the proper working of the Website.
          <br />
          <br />
          <b>15. &nbsp;Monitoring and Enforcement; Termination.</b>
          <br />
          <br />
          15.1. The Company has the right to:
          <br />
          a. Disclose your identity or other Personal Information about you to any third party who
          claims that material posted by you violates their rights, including their intellectual
          property rights or their right to privacy;
          <br />
          b. Take appropriate legal action, including but not limited to referral to law
          enforcement, for any illegal or unauthorized use of the Website; and
          <br />
          c. Terminate or suspend your access to all or part of the Website for any violation of
          these Terms of Service.
          <br />
          15.2. Without limiting the foregoing, the Company reserves the right to cooperate fully
          with any law enforcement authorities or court order requesting or directing the Company to
          disclose the identity or other Personal Information of anyone posting any materials on or
          through the Website.
          <br />
          15.3. However, the Company does not undertake to review all material before it is posted
          on the Website, and cannot ensure prompt removal of objectionable material after it has
          been posted. Accordingly, the Company assumes no liability for any action or inaction
          regarding transmissions, communications, or content provided by any User, End User, or
          other third party. The Company has no liability or responsibility to anyone for
          performance or nonperformance of the activities described in this section.
          <br />
          <br />
          <b>16. &nbsp;Reliance on Information Posted.</b>
          <br />
          <br />
          The information presented on or through the Website is made available solely for general
          information purposes (“General Information”). The Company does not warrant the accuracy,
          completeness, or usefulness of this General Information. Any reliance you place on such
          General Information is strictly at your own risk. The Company disclaims all liability and
          responsibility arising from any reliance placed on such materials by you or any other
          visitor to the Website, or by anyone who may be informed of any of its contents.
          <br />
          This Website may include content provided by third parties, including but not limited to
          advertisers. All statements and/or opinions expressed in these materials, other than the
          content expressly provided by the Company, are solely the opinions and the responsibility
          of the person or entity providing those materials. These materials do not necessarily
          reflect the opinion of the Company. The Company is not responsible or liable to you or any
          third party for the content or accuracy of any materials provided by any third parties.
          <br />
          <br />
          <b>17. &nbsp;Changes to the Website.</b>
          <br />
          <br />
          The Company may update the content on this Website from time to time, but the Website’s
          content is not necessarily complete or up-to-date. Any of the material on the Website may
          be out of date at any given time, and the Company is under no obligation to update such
          material.
          <br />
          <br />
          <b>18. &nbsp;Linking to the Website and Social Media Features.</b>
          <br />
          <br />
          18.1. You may link to the Company’s homepage, provided you do so in a way that is fair and
          legal and does not damage the Company’s reputation or take advantage of it, but you may
          not establish a link in such a way as to suggest any form of association, approval, or
          endorsement on the part of the Company without the Company’s express prior written
          consent.
          <br />
          18.2. This Website may provide certain social media features that enable you to:
          <br />
          a. Link from your own or certain third party websites to certain content on this Website;
          or
          <br />
          b. Cause limited portions of the content on this Website to be displayed or appear to be
          displayed on your own or certain third party websites.
          <br />
          18.3. You may use these features solely as they are provided by the Company, and solely
          with respect to the content they are displayed with. Subject to the foregoing, you may
          not:
          <br />
          a. Establish a link from any website that is not owned by you;
          <br />
          b. Cause the Website or portions of it to be displayed, or appear to be displayed by, for
          <br />
          example, framing, deep linking, or in-line linking, on any other site;
          <br />
          c. Link to any part of the Website other than the homepage; or
          <br />
          d. Otherwise take any action with respect to the materials on this Website that is
          inconsistent with any other provision of these Terms of Service.
          <br />
          18.4. You agree to cooperate with the Company in causing any unauthorized framing or
          linking immediately to cease. The Company reserves the right to withdraw linking
          permission without notice.
          <br />
          18.5. The Company may disable all or any social media features and any links at any time
          without notice in the Company’s sole discretion.
          <br />
          <br />
          <b>19. &nbsp;Geographic Restrictions.</b>
          <br />
          <br />
          The Company is registered in the state of Delaware in the United States. The Company
          provides this Website for use only by persons located in the United States and makes no
          claims that the Website or any of its content is accessible or appropriate outside of the
          United States. Access to the Website may not be legal by certain persons or in certain
          countries. If you access the Website from outside the United States, you do so on your own
          initiative and are responsible for compliance with local laws.
          <br />
          <br />
          <b>20. &nbsp;Disclaimer of Warranties..</b>
          <br />
          <br />
          You understand that the Company cannot and does not guarantee or warrant that files
          available for downloading from the Internet or the Website will be free of viruses or
          other destructive code. You are responsible for implementing sufficient procedures and
          checkpoints to satisfy your particular requirements for anti-virus protection and accuracy
          of data input and output and for maintaining a means external to our site for any
          reconstruction of any lost data. The Company shall not be liable for any loss or damage
          caused by a distributed denial-of-service attack, viruses, or other technologically
          harmful material that may infect your computer equipment, computer programs, data, or
          other proprietary material due to your use of the Website. Your use of the Website, its
          content, and any services or items obtained through the Website is at your own risk. The
          Website, its content, and any services or items obtained through the Website are provided
          on an “as is” and “as available” basis, without any warranties of any kind, either express
          or implied. Neither the Company nor any person associated with the Company makes any
          warranty or representation with respect to the completeness, security, reliability,
          quality, accuracy, or availability of the Website. The Company hereby disclaims all
          warranties of any kind, whether express or implied, statutory, or otherwise, including but
          not limited to any warrantied of merchantability, non-infringement, and fitness for a
          particular purpose. The foregoing does not affect any warranties which cannot be excluded
          or limited under applicable law.
          <br />
          <br />
          <b>21. &nbsp;Limitation on Liability.</b>
          <br />
          <br />
          In no event will the Company, its affiliates, licensors, employees, agents, officers, or
          directors be liable for damages of any kind, under any legal theory, arising out of or in
          connection with your use, or inability to use, the Website. The foregoing does not affect
          any liability that cannot be excluded or limited under applicable law.
          <br />
          <br />
          <b>22. &nbsp;Indemnification.</b>
          <br />
          <br />
          You agree to defend, indemnify, and hold harmless the Company, its affiliates, licensors,
          and their respective officers, directors, employees, contractors, agents, licensors,
          suppliers, successors, and assigns from and against any claims, liabilities, damages,
          judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys’ fees)
          arising out of or relating to your violation of these Terms of Service or your use of the
          Website, including but not limited to any use of the Website's content, services, and
          products other than as expressly authorized in these Terms of Service.
          <br />
          <br />
          <b>23. &nbsp;Governing Law.</b>
          <br />
          <br />
          All matters relating to the Website and these Terms of Service and any dispute or claim
          arising therefrom or related thereto (in each case, including non-contractual disputes or
          claims), shall be governed by and construed in accordance with the internal laws of the
          State of Delaware
          <br />
          <br />
          <b>24. &nbsp;Dispute Resolution.</b>
          <br />
          <br />
          You agree to attempt in good faith to resolve any dispute, claim, or controversy arising
          out of or relating to these Terms of Service including the documents it incorporates by
          reference. Nonetheless, legal action taken by the Company to collect any fees, recover
          damages for, or obtain an injunction relating to the operations of the Website operations
          or intellectual property shall not be submitted to mediation or arbitration except as
          otherwise agreed to in writing by the Company. In addition, either you or the Company may
          seek any interim or preliminary relief from a court of competent jurisdiction in the State
          of Delaware necessary to protect the rights or property of you or the Company pending the
          completion of arbitration.
          <br />
          <br />
          <b>25. &nbsp;Waiver and Severability.</b>
          <br />
          <br />
          No waiver of by the Company of any term or condition set forth in these Terms of Service
          shall be deemed a further or continuing waiver of such term or condition or a waiver of
          any other term or condition, and any failure of the Company to assert a right or provision
          under these Terms of Service shall not constitute a waiver of such right or provision.
          <br />
          If any provision of these Terms of Service is held by a court or other tribunal of
          competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such
          provision shall be eliminated or limited to the minimum extent such that the remaining
          provisions of the Terms of Service will continue in full force and effect.
          <br />
          <br />
          <b>26. &nbsp;Comments and Concerns.</b>
          <br />
          <br />
          This Website is operated by Fisherman Technologies, Inc. Except as otherwise indicated
          above, all other feedback, comments, requests for technical support, and other
          communications relating to the Website should be directed to:{' '}
          <a href="mailto:info@gofisherman.com">info@gofisherman.com</a>.
          <br />
          <br />
          Thank you for visiting the Website.
        </div>
      </div>
    );
  }
}
