const baseConfig = {
  siteTitle: 'Fisherman',
  siteTitleAlt: 'Fisherman Websites',
  siteLogo: '',
  siteUrl: 'https://www.gofisherman.com',
  siteDescription:
    'Hook Customers with a Better Website - We build user friendly websites optimized to drive traffic and increase sales for your business online.',
  schema: 'LocalBusiness',
  keywords: 'fisherman websites, restaurants, websites, startup',
  pageMetadata: {
    home: {
      title: 'HomeHero',
      description:
        'Hook Customers with a Better Website - We build user friendly websites optimized to drive traffic and increase sales for your business online.',
      path: '/',
    },
    privacy_policy: {
      title: 'Privacy Policy',
      description: "Fisherman's Privacy Policy",
      path: '/privacy_policy',
    },
    instagram: {
      title: 'Instagram',
      description: 'Get your Instagram feed on your Fisherman site',
      path: '/instagram',
    },
    verify_business: {
      title: 'Verify Business',
      description: 'Verify Your Business',
      path: '/verify_business',
    },
    claim_website: {
      title: 'Claim Business Website',
      description: 'Claim Your Business Website',
      path: '/claim_website',
    },
    remove_website: {
      title: 'Remove Business Website',
      description: 'Remove Your Business Website',
      path: '/remove_website',
    },
    shift4: {
      title: 'Shift4 Partnership',
      description: "Fisherman's discounted offer for Shift4 customers",
      path: '/shift4',
    },
    shift4faq: {
      title: 'Shift4 FAQ',
      description: 'Frequently asked questions for Shift4 customers using Fisherman',
      path: '/shift4/faq',
    },
  },
};

export default baseConfig;
